var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('gl-button',{directives:[{name:"gl-modal",rawName:"v-gl-modal",value:(_vm.$options.modalId),expression:"$options.modalId"}],attrs:{"variant":"link"}},[_c('gl-sprintf',{attrs:{"message":_vm.__('Email a new %{name} to this project')},scopedSlots:_vm._u([{key:"name",fn:function(){return [_vm._v(_vm._s(_vm.issuableName))]},proxy:true}])})],1),_vm._v(" "),_c('gl-modal',{ref:"modal",attrs:{"modal-id":_vm.$options.modalId},scopedSlots:_vm._u([{key:"modal-title",fn:function(){return [_c('gl-sprintf',{attrs:{"message":_vm.__('Create new %{name} by email')},scopedSlots:_vm._u([{key:"name",fn:function(){return [_vm._v(_vm._s(_vm.issuableName))]},proxy:true}])})]},proxy:true},{key:"modal-footer",fn:function(){return [_c('gl-button',{attrs:{"category":"secondary"},on:{"click":_vm.cancelHandler}},[_vm._v(_vm._s(_vm.__('Cancel')))])]},proxy:true}])},[_vm._v(" "),_c('p',[_c('gl-sprintf',{attrs:{"message":_vm.__(
            'You can create a new %{name} inside this project by sending an email to the following email address:',
          )},scopedSlots:_vm._u([{key:"name",fn:function(){return [_vm._v(_vm._s(_vm.issuableName))]},proxy:true}])})],1),_vm._v(" "),_c('gl-form-input-group',{staticClass:"gl-mb-4",attrs:{"value":_vm.email,"readonly":"","select-on-click":""},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('modal-copy-button',{attrs:{"text":_vm.email,"title":_vm.__('Copy'),"modal-id":_vm.$options.modalId}}),_vm._v(" "),_c('gl-button',{directives:[{name:"gl-tooltip",rawName:"v-gl-tooltip.hover",modifiers:{"hover":true}}],attrs:{"href":_vm.mailToLink,"title":_vm.$options.i18n.sendEmail,"aria-label":_vm.$options.i18n.sendEmail,"icon":"mail"}})]},proxy:true}])}),_vm._v(" "),_c('p',[_c('gl-sprintf',{attrs:{"message":_vm.__(
            'The subject will be used as the title of the new issue, and the message will be the description. %{quickActionsLinkStart}Quick actions%{quickActionsLinkEnd} and styling with %{markdownLinkStart}Markdown%{markdownLinkEnd} are supported.',
          )},scopedSlots:_vm._u([{key:"quickActionsLink",fn:function({ content }){return [_c('gl-link',{attrs:{"href":_vm.quickActionsHelpPath,"target":"_blank"}},[_vm._v(_vm._s(content))])]}},{key:"markdownLink",fn:function({ content }){return [_c('gl-link',{attrs:{"href":_vm.markdownHelpPath,"target":"_blank"}},[_vm._v(_vm._s(content))])]}}])})],1),_vm._v(" "),_c('p',[_c('gl-sprintf',{attrs:{"message":_vm.__(
            'This is a private email address %{helpIcon} generated just for you. Anyone who has it can create issues or merge requests as if they were you. If that happens, %{resetLinkStart}reset this token%{resetLinkEnd}.',
          )},scopedSlots:_vm._u([{key:"helpIcon",fn:function(){return [_c('gl-link',{attrs:{"href":_vm.emailsHelpPagePath,"target":"_blank"}},[_c('help-icon')],1)]},proxy:true},{key:"resetLink",fn:function({ content }){return [_c('gl-button',{attrs:{"variant":"link","data-testid":"reset_email_token_link"},on:{"click":_vm.resetIncomingEmailToken}},[_vm._v("\n            "+_vm._s(content)+"\n          ")])]}}])})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }