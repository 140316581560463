var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.displayFilters)?_c('div',{staticClass:"full-width-mobile gl-inline-block gl-align-bottom",attrs:{"id":"discussion-preferences","data-testid":"discussion-preferences"}},[_c('local-storage-sync',{attrs:{"value":_vm.sortDirection,"storage-key":_vm.sortStorageKey,"persist":_vm.persistSortOrder,"as-string":""},on:{"input":function($event){return _vm.setDiscussionSortDirection({ direction: $event })}}}),_vm._v(" "),_c('gl-disclosure-dropdown',{staticClass:"full-width-mobile",attrs:{"id":"discussion-preferences-dropdown","data-testid":"discussion-preferences-dropdown","toggle-text":_vm.__('Sort or filter'),"disabled":_vm.isLoading,"placement":"bottom-end"}},[_c('gl-disclosure-dropdown-group',{attrs:{"id":"discussion-sort"}},_vm._l((_vm.$options.SORT_OPTIONS),function({ text, key, cls }){return _c('gl-disclosure-dropdown-item',{key:text,class:cls,attrs:{"is-selected":_vm.isSortDropdownItemActive(key)},on:{"action":function($event){return _vm.fetchSortedDiscussions(key)}},scopedSlots:_vm._u([{key:"list-item",fn:function(){return [_c('gl-icon',{class:[
              'gl-dropdown-item-check-icon',
              { 'gl-invisible': !_vm.isSortDropdownItemActive(key) },
              'gl-text-blue-400',
            ],attrs:{"name":"mobile-issue-close","data-testid":"dropdown-item-checkbox"}}),_vm._v("\n          "+_vm._s(text)+"\n        ")]},proxy:true}],null,true)})}),1),_vm._v(" "),_c('gl-disclosure-dropdown-group',{staticClass:"discussion-filter-container js-discussion-filter-container",attrs:{"id":"discussion-filter","bordered":""}},_vm._l((_vm.filters),function(filter){return _c('gl-disclosure-dropdown-item',{key:filter.value,class:{ 'is-active': filter.value === _vm.currentValue },attrs:{"is-selected":filter.value === _vm.currentValue,"data-filter-type":_vm.filterType(filter.value),"data-testid":"filter-menu-item"},on:{"action":function($event){return _vm.selectFilter(filter.value)}},scopedSlots:_vm._u([{key:"list-item",fn:function(){return [_c('gl-icon',{class:[
              'gl-dropdown-item-check-icon',
              { 'gl-invisible': filter.value !== _vm.currentValue },
              'gl-text-blue-400',
            ],attrs:{"name":"mobile-issue-close","data-testid":"dropdown-item-checkbox"}}),_vm._v("\n          "+_vm._s(filter.title)+"\n        ")]},proxy:true}],null,true)})}),1)],1)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }